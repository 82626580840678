import { createContext, useContext, useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';

const UserAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem('user')) || null
    );

    async function signIn(email, password) {
        const response = await signInWithEmailAndPassword(auth, email, password);
        setUser(response.user);
        localStorage.setItem('user', JSON.stringify(response.user));
    }

    // function signUp(email, password) {
    //     return createUserWithEmailAndPassword(auth, email, password);
    // }

    async function signout() {
        await signOut(auth);
        setUser(null);
        localStorage.removeItem('user');
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            localStorage.setItem('user', JSON.stringify(currentUser));
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserAuthContext.Provider value={{user, signIn, signout}}>
            {children}
        </UserAuthContext.Provider>
    )
}

export function  useUserAuth() {
    return useContext(UserAuthContext);
}