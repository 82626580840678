import "./App.css";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import NotFound from "./Pages/NotFound";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import GenenrateQR from "./Pages/GenenrateQR";

function App() {
  return (
    <Router>
      <div>
        <section>
          <UserAuthContextProvider>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/generateqr" element={<GenenrateQR />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </UserAuthContextProvider>
        </section>
      </div>
    </Router>
  );
}

export default App;
