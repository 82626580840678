import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ searchTerm, handleSearchChange, logoutFunction, name }) => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <h3>
        <Link class="navbar-brand mx-2" to="/">
          VQR Admin
        </Link>
      </h3>

      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <div className="d-flex bd-highlight w-100">
          <div className="p-2 flex-grow-1 bd-highlight">
            <form className="form-outline w-75 mt-1" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search by Name or VQR ID.."
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              ></input>
            </form>
          </div>

          <div className="p-2 bd-highlight">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <Link to="/generateqr" className="btn btn-outline-secondary me-2" >
              Generate VQR
            </Link>
            </li>
              <li className="nav-item me-3 me-lg-1">
                <span
                  className="nav-link d-sm-flex align-items-sm-center"
                >
                  <img
                    src="https://i.pinimg.com/originals/3f/34/b2/3f34b2b917029cc9a0caddaa0a4454fb.png"
                    className="rounded-circle"
                    height="30"
                    alt="Black and White Portrait of a Man"
                    loading="lazy"
                  />
                  <strong className="d-none d-sm-block ms-1">
                    {name}
                  </strong>
                </span>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-outline-danger mt-1 me-2"
                  type="submit"
                  onClick={logoutFunction}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
