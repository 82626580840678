import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({ data }) => {
  const [statuses, setStatuses] = useState({
    // Initialize the statuses based on the data
    // This assumes each data item has a unique ID and status property
    // You should adjust this based on your data structure
    // initialize all statuses
    ...data.reduce((acc, item) => ({ ...acc, [item.id]: item.paymentStatus }), {}),
  });

  const handleStatusChange = (itemId, newStatus) => {
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [itemId]: newStatus,
    }));
  };

  return (
    <div className="accordion">
      {data.map((item) => (
        <AccordionItem
          id={item.id}
          email={item.email}
          name={item.name}
          pic={item.profile_pic}
          currentStatus={statuses[item.id]}
          onStatusChange={(newStatus) => handleStatusChange(item.id, newStatus)}
          paymentID={item.paymentID}
          paymentAddress={item.shippingAddress}
          paymentEmail={item.shippingEmail}
          paymentPhoneNumber={item.shippingPhone}
        />
      ))}
    </div>
  );
};

export default Accordion;
