import React, { useState, useEffect } from "react";
import Navbar from "../components/NavBar/NavBar";
import Accordion from "../components/AccordionComp/Accordion";
import { useUserAuth } from "../context/UserAuthContext";
import endpoint from "../credentials";

export default function Home() {
  const { user, signout } = useUserAuth();

  async function LogoutHandel() {
    try {
      await signout();
    } catch (error) {
      alert(error.message);
    }
  }

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(endpoint+"paidusers");
        const jsonData = await response.json();
        console.log("Response from server:", jsonData);
        setData(jsonData); // Assuming the data is directly returned as an array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error fetching data");
      }
    };
  
    fetchData();
  }, []);
  
  


  const filteredData = data.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.email.includes(searchTerm);
  });

  return (
    <div className="App">
      <Navbar
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        logoutFunction={LogoutHandel}
        name={user.email}
      />
      <div className="h-75 d-inline-block my-5"></div>
      <div className="container my-3">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <Accordion data={filteredData} />
        )}
      </div>
    </div>
  );
}
