import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';

export default function ProtectedRoute() {
    let { user } = useUserAuth();
    if (!user) {
        return <Navigate to="/login" />
    }
  return <Outlet />;
}
