import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="container mt-5">
    <div className="text-center">
      <h1 className="display-1">404</h1>
      <p className="lead">Page not found</p>
      <p className="text-muted">The page you're looking for does not exist.</p>
      <Link to="/" className="btn btn-primary">
        Go to Home
      </Link>
    </div>
  </div>
  )
}
