import React, { useState, useEffect } from "react";
import endpoint from "../../credentials";

function getColor(mystatus) {
  let status = "success";
  if (mystatus === "pending") {
    status = "warning";
  } else if (mystatus === "cancelled") {
    status = "danger";
  }
  return status;
}

async function updateStatus(id, status) {
  try {
    const response = await fetch(endpoint+`updateDocument/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentStatus: status })  // Adjust the field and value to update
    });

    if (response.ok) {
      console.log('Document updated successfully');
    } 
  } catch (error) {
    alert('Error updating document:', error);
  }
}

const AccordionItem = ({
  id,
  email,
  name,
  pic,
  currentStatus,
  onStatusChange,
  paymentID,
  paymentAddress,
  paymentEmail,
  paymentPhoneNumber,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);

  useEffect(() => {
    setSelectedStatus(currentStatus);
  }, [currentStatus]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  const handleUpdateStatus = () => {
    onStatusChange(selectedStatus);
    updateStatus(id, selectedStatus);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${id}`}>
        <div
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={toggleAccordion}
        >
          <img
            src= {pic || "https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"} 
            className="rounded-circle  mx-3 "
            style={{ width: "60px", height: "60px" }}
            alt="Avatar"
            referrerpolicy="no-referrer"
          />
          <div className="col">
            <div className="row">
              <div className="col-md-auto">
                <h4 className="text-primary-emphasis mx-1 mt-2">{name}</h4>
              </div>
              <div className="col">
                <div
                  className={`badge bg-${getColor(
                    currentStatus
                  )} text-wrap fs-7 mt-3`}
                >
                  Status: {currentStatus}
                </div>
              </div>
            </div>
            <p className="text-primary-emphasis mx-1">VQR ID: {email}</p>
          </div>
        </div>
      </h2>
      <div
        id={`collapse${id}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading${id}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="row">
          <div className="row my-3">
            <div className="col">
              <strong>Payment ID</strong> <div></div> {paymentID || "N/A"}
            </div>
            <div className="col">
              <strong>Payment Address</strong> <div></div>{paymentAddress || "N/A"}
            </div>
            <div className="col">
              <strong>Shipping Phone Number</strong> <div></div> {paymentPhoneNumber || "N/A"}
            </div>
            <div className="col">
              <strong>Email</strong> <div></div> {paymentEmail || "N/A"}
            </div>
          </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="Pending"
                  checked={selectedStatus === "pending"}
                  onChange={() => handleStatusChange("pending")}
                />
                Pending
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="Success"
                  checked={selectedStatus === "success"}
                  onChange={() => handleStatusChange("success")}
                />
                Paid
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="Cancelled"
                  checked={selectedStatus === "cancelled"}
                  onChange={() => handleStatusChange("cancelled")}
                />
                Cancelled
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="Approved"
                  checked={selectedStatus === "approved"}
                  onChange={() => handleStatusChange("approved")}
                />
                Order Aproved
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="shipping"
                  checked={selectedStatus === "shipping"}
                  onChange={() => handleStatusChange("shipping")}
                />
                Shipping
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="outOfDelivery"
                  checked={selectedStatus === "outfordelivery"}
                  onChange={() => handleStatusChange("outfordelivery")}
                />
                Out for Delivery
              </label>
            </div>
            <div className="col">
              <label>
                <input
                  type="radio"
                  value="Completed"
                  checked={selectedStatus === "completed"}
                  onChange={() => handleStatusChange("completed")}
                />
                Completed
              </label>
            </div>
            <div className="col">
              <button className="btn btn-primary" onClick={handleUpdateStatus}>
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
