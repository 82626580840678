import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import endpoint from "../credentials";
import noimage from '../assets/noimage.png';
import jsPDF from "jspdf";
import QRCode from "qrcode";

const CardComponent = ({ item, isSelected, onCardClick }) => {
  const cardStyle = {
    border: isSelected ? "2px solid blue" : "2px solid transparent",
    cursor: "pointer",
    marginBottom: "10px", // Add margin between cards
    padding: "10px", // Add padding for better appearance
  };

  return (
    <div className="card" style={cardStyle} onClick={() => onCardClick(item)}>
      <div className="card-body">
      <div className="row justify-content-start">
        <div className="col-sm-1">
        <img
            src={item.profile_pic || noimage}
            className="rounded-circle  mx-3 "
            style={{ width: "60px", height: "60px" }}
            alt="Avatar"
            referrerpolicy="no-referrer"
          />
        </div>
        <div className="col">
        {item.name !== null ? (
          <h5 className="card-title">{item.name}</h5>
        ) : (
          <h5 className="card-title text-danger">Name Not Registered</h5>
        )}
        <p className="card-text">VQR ID: {item.email}</p>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => {}}
          onClick={(e) => e.stopPropagation()} // Prevent card click on checkbox click
        />
        </div>

      </div>
      </div>
    </div>
  );
};

export default function GenenrateQR() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(endpoint+"allusers");
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Error fetching data");
      }
    };

    fetchData();
  }, []);

  const handleCardClick = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (selectedItem) => selectedItem.id === item.id
      );

      if (itemIndex !== -1) {
        // Item already selected, remove it from the list
        const updatedSelectedItems = prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
        return updatedSelectedItems;
      } else {
        // Item not selected, add it to the list
        return [...prevSelectedItems, item];
      }
    });
  };

  const handleButtonClick = async () => {
    console.log("Selected Items:", selectedItems);
    // You can perform additional actions with the selected items here
    if (selectedItems.length === 0) {
      alert("Please select at least one profile to generate QR.");
      return;
    }

    // Get the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().replace(/[:.]/g, "-"); // Format as "YYYY-MM-DDTHH-mm-ss"
  
    const pdf = new jsPDF();

    const itemsPerPage = 4; //Number of User's QR per page
  
    for (let pageIndex = 0; pageIndex < Math.ceil(selectedItems.length / itemsPerPage); pageIndex++) {
  
      // Add a new page only if there are more than itemsPerPage items
      if (pageIndex !== 0) {
        pdf.addPage();
      }

      for (let itemIndex = 0; itemIndex < itemsPerPage; itemIndex++) {
        const arrayIndex = pageIndex * itemsPerPage + itemIndex;
  
        if (arrayIndex >= selectedItems.length) {
          break; // Exit the loop if there are no more items
        }
  
        const item = selectedItems[arrayIndex];
  
        pdf.setFontSize(10);

        const xBorder = 13;
        const yBorder = 14;
        const gap = 3;

  
        // Customize the content for each page (name and email)
        // pdf.text(`Name: ${item.name || "Name Not Registered"}`, xBorder, 15 + itemIndex * 70);
        // pdf.text(`VQR: ${item.email || ""}`, xBorder, 13 + itemIndex * 68);
  
        // ADD ALL IMAGES BELOW
  
        const qrCanvas = await QRCode.toCanvas(item.email || "", {
          scale: 4,
          color: {
            dark: "#ffffff", // actual QR code colour
            light: "#00000000", // same as background colour to hide (transparent here)
          },
        });
        const qrImageData = qrCanvas.toDataURL("image/png");

  
        // Load a background image (replace 'path_to_background_image' with your image path)
        const bgImagePath1 = 'VQRBG1.png';
        const bgImageInfo1 = await getImageInfo(bgImagePath1);

        const bgImagePath2 = 'VQRBG2.png';
        const bgImageInfo2 = await getImageInfo(bgImagePath2);

        const bgImagePath3 = 'VQRBG3.png';
        const bgImageInfo3 = await getImageInfo(bgImagePath3);
  
        // Calculate the position and size for the background image 1
        const bgImageX1 = xBorder;
        const bgImageY1 = yBorder + itemIndex * 68;
        const bgImageWidth1 = 60;
        const bgImageHeight1 = 60;

        // Calculate the position and size for the background image 2
        const bgImageX2 = xBorder+60+gap;
        const bgImageY2 = yBorder + itemIndex * 68;
        const bgImageWidth2 = 60;
        const bgImageHeight2 = 60;

        // Calculate the position and size for the background image 3
        const bgImageX3 = xBorder+60+gap+60+gap;
        const bgImageY3 = yBorder + itemIndex * 68;
        const bgImageWidth3 = 60;
        const bgImageHeight3 = 60;
  
        // Add the background image to the PDF
        pdf.addImage(bgImageInfo1.data, bgImageInfo1.format, bgImageX1, bgImageY1, bgImageWidth1, bgImageHeight1);
        pdf.addImage(bgImageInfo2.data, bgImageInfo2.format, bgImageX2, bgImageY2, bgImageWidth2, bgImageHeight2);
        pdf.addImage(bgImageInfo3.data, bgImageInfo3.format, bgImageX3, bgImageY3, bgImageWidth3, bgImageHeight3);
  
        // Calculate the position and size for the QR code image
        const qrImageX = xBorder + 22.4;
        const qrImageY = yBorder + 17.38 + itemIndex * 68;
        const qrImageWidth = 15.5;
        const qrImageHeight = 15.5;

        // QR2 Position
        const qrImageX2 = xBorder + 60 + gap + 22.36;
        const qrImageY2 = yBorder + 17.2 + itemIndex * 68;
        const qrImageWidth2 = 16;
        const qrImageHeight2 = 16;

        // QR3 Position
        const qrImageX3 = xBorder + 60 + gap + 60 + gap + 13.2;
        const qrImageY3 = yBorder + 10.42 + itemIndex * 68;
        const qrImageWidth3 = 9.8;
        const qrImageHeight3 = 9.8;
  
        // Add the QR code image on top of the background image
        pdf.addImage(qrImageData, "PNG", qrImageX, qrImageY, qrImageWidth, qrImageHeight);
        pdf.addImage(qrImageData, "PNG", qrImageX2, qrImageY2, qrImageWidth2, qrImageHeight2);
        pdf.addImage(qrImageData, "PNG", qrImageX3, qrImageY3, qrImageWidth3, qrImageHeight3);
      }
    }

    // Save or open the PDF as needed
    pdf.save(`VQR_${formattedDate}.pdf`);
  };

  // Helper function to get image information
  function getImageInfo(imageName) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const imagePath = require(`../assets/${imageName}`); // Assuming imageName is the file name in the assets folder
  
      img.src = imagePath;
      img.onload = () => {
        console.log("Image loaded successfully:", imagePath);
        resolve({ data: img, format: "PNG" });
      };
      img.onerror = (error) => {
        console.error("Error loading image:", error);
        reject(error);
      };
    });
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((item) =>
    item.name != null && item.email != null
      ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.email.includes(searchTerm)
      : item
  );

  return (
    <div>
      <nav className="navbar navbar-light bg-light fixed-top">
        <div className="row">
          <div className="col">
            <h3>
              <Link class="navbar-brand ms-4" to="/">
                VQR Admin
              </Link>
            </h3>
          </div>
          <div className="col">
            <form className="form-outline" role="search">
              <input
                className="form-control"
                type="search"
                style={{ width: "600px" }}
                placeholder="Search by title"
                aria-label="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <button
              className="btn btn-primary"
              style={{ width: "150px" }}
              onClick={handleButtonClick}
            >
              Generate QR
            </button>
          </div>
          <div className="col">
            <Link to="/" className="btn btn-warning me-4">
              Go to Home
            </Link>
          </div>
        </div>
      </nav>
      <div className="my-5" style={{ height: "5px" }}></div>
      <div className="container my-5">
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row">
            {filteredData.map((item) => (
              <div key={item.id}>
                <CardComponent
                  item={item}
                  isSelected={selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id
                  )}
                  onCardClick={handleCardClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
