// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBsITt3zKS8c7K5TH7-UYcs-bJ2a5t2LQk",
  authDomain: "vqr-app.firebaseapp.com",
  projectId: "vqr-app",
  storageBucket: "vqr-app.appspot.com",
  messagingSenderId: "469583365245",
  appId: "1:469583365245:web:8f7be34cd88c67316ddd14",
  measurementId: "G-WEE58BMYZL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
